/* index.css */
@font-face {
  font-family: Ubuntu-Regular;
  src: url('../fonts/ubuntu/Ubuntu-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Ubuntu-Medium;
  src: url('../fonts/ubuntu/Ubuntu-Medium.ttf') format('truetype');
}

@font-face {
  font-family: Ubuntu-Bold;
  src: url('../fonts/ubuntu/Ubuntu-Bold.ttf') format('truetype');
}

@font-face {
  font-family: Ubuntu-Light;
  src: url('../fonts/ubuntu/Ubuntu-Light.ttf') format('truetype');
}

:root {
  --bs-body-font-family: 'Ubuntu-Regular', sans-serif;
  --motion-ease: cubic-bezier(0.68, -0.6, 0.32, 1.6);
  --motion-duration: 0.3s;
}

* {
  margin: 0px; 
  padding: 0px; 
  box-sizing: border-box;
}

body, html {
  height: 100%;
  font-family: var(--bs-body-font-family);
}

h1,h2,h3,h4,h5,h6 {
  margin: 0px;
  padding: 0px;
}

p {
  font-family: Ubuntu-Regular;
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  padding: 0px;
}

/* tambahan */
.h-10 {
  height: 10% !important;
}

.h-15 {
  height: 15% !important;
}

.h-20 {
  height: 20% !important;
}

.h-30 {
  height: 30% !important;
}

.h-35 {
  height: 35% !important;
}

.h-40 {
  height: 40% !important;
}

.h-60 {
  height: 60% !important;
}

.h-70 {
  height: 70% !important;
}

.h-80 {
  height: 80% !important;
}

.h-90 {
  height: 90% !important;
}

/*class beranda */

.limiter {
  width: 100%;
  margin: 0 auto;
}

.background-container {
  width: 100%;  
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url('../images/bg.svg');
}

.wrap{
  width: 97%;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding: 20px 0 0 0;
}